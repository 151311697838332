import makeOrganizationSchemaObject from "../objects/organization";
import makeWebSiteSchemaObject from "../objects/website";
import makeCollectionPageSchemaObject from "../objects/collectionPage";
import {
  getProductCatalogUrl,
  getProductCategoryUrl,
  getProductSubcategoryUrl,
} from "../../meta";
import makeBreadcrumbsProductsListSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductsList";

export default function makeProductsListSchema(site, category, subcategory, ctx) {
  return [
    makeProductsListSchemaHelper(site, category, subcategory, ctx),
    makeBreadcrumbsProductsListSchema(category, subcategory, ctx),
  ].map(schema => ({
    type: "application/ld+json",
    innerHTML: JSON.stringify(schema),
  }));
}

function makeProductsListSchemaHelper(site, category, subcategory, ctx) {
  let collectionPageSchemaData;
  if (subcategory) {
    collectionPageSchemaData = {
      url: getProductSubcategoryUrl(category.getSlug(), subcategory.getSlug(), ctx),
      name: `${subcategory.getName()} - ${site.getName()}`,
      description:
        (subcategory ? subcategory.getDescription() : null) || site.getDescription(),
    };
  } else if (category) {
    collectionPageSchemaData = {
      url: getProductCategoryUrl(category.getSlug(), ctx),
      name: `${category.getName()} - ${site.getName()}`,
      description: (category ? category.getDescription() : null) || site.getDescription(),
    };
  } else {
    collectionPageSchemaData = {
      url: getProductCatalogUrl(ctx),
      name: site.getName(),
      description: site.getDescription,
    };
  }

  return {
    "@context": "https://schema.org/",
    "@graph": [
      makeOrganizationSchemaObject(site, ctx),
      makeWebSiteSchemaObject(site, ctx),
      makeCollectionPageSchemaObject(collectionPageSchemaData),
    ],
  };
}
